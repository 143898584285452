<template>
  <div class="main">
    <Modal
      v-if="isModalDetail"
      @close="() => (isModalDetail = false)"
      width="40%"
    >
      <div class="title-container d-flex justify-center">
        <h2 class="modal-title me-3" style="font-weight: bolder">
          Detail User Aktif
        </h2>
        <div class="info-icon-wrapper">
          <InfoIcon class="info-icon align-items-center" />
          <div class="tooltip">Detail informasi data pembelian user</div>
        </div>
      </div>

      <br />

      <div class="modal-body" v-if="selectedUser">
        <div class="data-list">
          <div
            class="detail-data-1"
            style="
              width: 233px;
              border: 1px solid #c3c3c6;
              text-align: left;
              border-radius: 10px;
            "
          >
            <p class="detail-label">No.Invoice</p>
            <p class="detail-value">{{ selectedUser.invoice_number }}</p>
          </div>
          <div
            class="detail-data-2"
            style="
              width: 500px;
              border: 1px solid #c3c3c6;
              text-align: left;
              border-radius: 10px;
            "
          >
            <p class="detail-label">Waktu Pembelian</p>
            <p class="detail-value">{{ selectedUser.payment_time }}</p>
          </div>
        </div>
        <div class="data-list">
          <div class="detail-data">
            <p class="detail-label">Nama Lengkap</p>
            <p class="detail-value">
              {{ selectedUser.name }}
            </p>
          </div>
        </div>
        <div class="data-list">
          <div class="detail-data">
            <p class="detail-label">No.Handphone</p>
            <p class="detail-value">(+62) {{ selectedUser.phone_number }}</p>
          </div>
          <div class="detail-data">
            <p class="detail-label">Email</p>
            <p class="detail-value">{{ selectedUser.email }}</p>
          </div>
        </div>

        <div class="data-list">
          <div class="detail-data">
            <p class="detail-label">Voucher</p>
            <p
              class="detail-value"
              v-html="getVouchers(selectedUser.voucher)"
            ></p>
          </div>
          <div class="detail-data">
            <p class="detail-label">Nominal Transaksi</p>
            <p class="detail-value">
              IDR. {{ formatPrice(selectedUser.no_transaction[0]) }}
              <span
                v-for="(discount, index) in selectedUser.no_transaction[1]
                  .discounts"
                :key="index"
              >
                - IDR. {{ formatPrice(discount) }}
              </span>
              =
              <span style="color: #b80f0a">
                IDR.
                {{
                  formatPrice(calculateTotalPrice(selectedUser.no_transaction))
                }}
              </span>
            </p>
          </div>
        </div>
      </div>
    </Modal>

    <div class="breadcrumb-download">
      <v-breadcrumbs :items="items">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
      <button
        class="btn-riwayat"
        @click="() => $router.push(`/transaction/b2b/event/history/` + title)"
      >
        Riwayat
      </button>
    </div>

    <div class="table-container">
      <div class="filter">
        <v-text-field
          prepend-inner-icon="mdi-magnify"
          outlined
          width="233"
          class="form-input"
          placeholder="Cari Nama / No. Invoice"
          dense
          clearable
        ></v-text-field>

        <v-text-field
          outlined
          class="form-input"
          type="date"
          clearable
          dense
        ></v-text-field>
        <Button name="TERAPKAN" />
      </div>

      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th style="width: 100px">No. Invoice</th>
              <th style="width: 200px">Waktu Pembelian (WIB)</th>
              <th style="width: 100px">Nama</th>
              <th style="width: 200px">Program</th>
              <th style="width: 120px">Tipe Kelas</th>
              <th style="width: 280px">Voucher</th>
              <th style="width: 180px">Nominal Transaksi</th>
              <th style="width: 240px">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in participants" :key="item.invoice_number">
              <td>{{ item.invoice_number }}</td>
              <td>{{ item.payment_time }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.program }}</td>
              <td>{{ item.class_type }}</td>
              <td v-html="getVouchers(item.voucher)"></td>
              <td>
                <div class="transact-item">
                  <p>Normal Price</p>
                  <h4>IDR. {{ formatPrice(item.no_transaction[0]) }}</h4>
                </div>
                <div class="transact-item">
                  <p>Discount</p>
                  <div class="discount-item">
                    <h4
                      v-for="(discount, index) in item.no_transaction[1]
                        .discounts"
                      :key="index"
                    >
                      IDR. {{ formatPrice(discount) }}
                    </h4>
                  </div>
                </div>
                <div class="transact-item">
                  <p>Current Price</p>
                  <h4 style="color: #b80f0a">
                    IDR.
                    {{ formatPrice(calculateTotalPrice(item.no_transaction)) }}
                  </h4>
                </div>
              </td>
              <td>
                <div class="btn-actions">
                  <button
                    v-if="!isExpired && !isCanceled"
                    class="btn-edit"
                    @click="editUser(item)"
                  >
                    UBAH
                  </button>
                  <button class="btn-watch" @click="viewUser(item)">
                    LIHAT
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-row class="mt-4" v-if="totalData" style="color: #515151">
        Menampilkan
        {{ Math.floor((currentPage - 1) * 10 + items.length) }} dari
        {{ totalData }}
        data
      </v-row>

      <v-row justify="center" v-if="total">
        <v-col cols="4">
          <v-container class="max-width">
            <v-pagination
              v-model="currentPage"
              class="my-4"
              :length="total"
              @input="getUsers"
            ></v-pagination>
          </v-container>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
    
    
  <script>
import Button from "@/components/Button.vue";
import Modal from "@/components/Modal.vue";
import InfoIcon from "@/components/icons/InfoIcon.vue";
export default {
  name: "Participants",
  components: {
    Button,
    Modal,
    InfoIcon,
  },
  data: () => ({
    title: "Project Management",
    isExpired: false,
    isCanceled: false,
    currentPage: 10,
    total: 2,
    totalData: 20,
    items: [
      {
        text: "Finance",
        disabled: false,
        href: "/home",
      },
      {
        text: "(B2C) Event",
        disabled: false,
        href: "/transaction/b2b/event/",
      },
      {
        text: "Webinar",
        disabled: false,
        href: "/transaction/b2b/event/id",
      },
      {
        text: "UI/UX Design",
        disabled: true,
        href: "",
      },
    ],
    headers: [
      { text: "No. Invoice", value: "invoice_number", sortable: false },
      { text: "Waktu Pembelian (WIB)", value: "payment_time", sortable: false },
      { text: "Nama", value: "name", sortable: false },
      { text: "Program", value: "program", sortable: false },
      { text: "Tipe Kelas", value: "class_type", sortable: false },
      { text: "Voucher", value: "voucher", sortable: false },
      { text: "Nominal Transaksi", value: "no_transaction", sortable: false },
      { text: "Action", value: "action", sortable: false },
    ],
    participants: [
      {
        invoice_number: "#76520",
        payment_time: "08/05/24 14:00",
        name: "Ahmad Damar Rahayu",
        program: "Intermediate Golang Programming",
        class_type: "Public",
        voucher: ["HCINAJA", "HCDISC99"],
        no_transaction: [3940000, { discounts: [3940000, 3940000] }],
        phone_number: "0895608857548",
        email: "aldryansyah30@gmail.com",
      },
      {
        invoice_number: "#76521",
        payment_time: "08/05/24 15:00",
        name: "Budi Santoso",
        program: "Beginner Python Programming",
        class_type: "Private",
        voucher: ["PYTHON50", "DISC20"],
        no_transaction: [2940000, { discounts: [500000, 200000] }],
        phone_number: "08123456789",
        email: "budi@example.com",
      },
      {
        invoice_number: "#76522",
        payment_time: "08/05/24 16:00",
        name: "Citra Ayu",
        program: "Advanced Data Science",
        class_type: "Public",
        voucher: ["DATASCIENCE10"],
        no_transaction: [4940000, { discounts: [500000] }],
        phone_number: "08234567890",
        email: "citra@example.com",
      },
      {
        invoice_number: "#76522",
        payment_time: "08/05/24 16:00",
        name: "Citra Ayu",
        program: "Advanced Data Science",
        class_type: "Public",
        voucher: ["DATASCIENCE10"],
        no_transaction: [4940000, { discounts: [500000] }],
        phone_number: "08234567890",
        email: "citra@example.com",
      },
      {
        invoice_number: "#76522",
        payment_time: "08/05/24 16:00",
        name: "Citra Ayu",
        program: "Advanced Data Science",
        class_type: "Public",
        voucher: ["DATASCIENCE10"],
        no_transaction: [4940000, { discounts: [500000] }],
        phone_number: "08234567890",
        email: "citra@example.com",
      },
      {
        invoice_number: "#76522",
        payment_time: "08/05/24 16:00",
        name: "Citra Ayu",
        program: "Advanced Data Science",
        class_type: "Public",
        voucher: ["DATASCIENCE10"],
        no_transaction: [4940000, { discounts: [500000] }],
        phone_number: "08234567890",
        email: "citra@example.com",
      },
      {
        invoice_number: "#76522",
        payment_time: "08/05/24 16:00",
        name: "Citra Ayu",
        program: "Advanced Data Science",
        class_type: "Public",
        voucher: ["DATASCIENCE10"],
        no_transaction: [4940000, { discounts: [500000] }],
        phone_number: "08234567890",
        email: "citra@example.com",
      },
      {
        invoice_number: "#76522",
        payment_time: "08/05/24 16:00",
        name: "Citra Ayu",
        program: "Advanced Data Science",
        class_type: "Public",
        voucher: ["DATASCIENCE10"],
        no_transaction: [4940000, { discounts: [500000] }],
        phone_number: "08234567890",
        email: "citra@example.com",
      },
      {
        invoice_number: "#76522",
        payment_time: "08/05/24 16:00",
        name: "Citra Ayu",
        program: "Advanced Data Science",
        class_type: "Public",
        voucher: ["DATASCIENCE10"],
        no_transaction: [4940000, { discounts: [500000] }],
        phone_number: "08234567890",
        email: "citra@example.com",
      },
      {
        invoice_number: "#76522",
        payment_time: "08/05/24 16:00",
        name: "Citra Ayu",
        program: "Advanced Data Science",
        class_type: "Public",
        voucher: ["DATASCIENCE10"],
        no_transaction: [4940000, { discounts: [500000] }],
        phone_number: "08234567890",
        email: "citra@example.com",
      },
      {
        invoice_number: "#76522",
        payment_time: "08/05/24 16:00",
        name: "Citra Ayu",
        program: "Advanced Data Science",
        class_type: "Public",
        voucher: ["DATASCIENCE10"],
        no_transaction: [4940000, { discounts: [500000] }],
        phone_number: "08234567890",
        email: "citra@example.com",
      },
    ],
    selectedUser: null, // New data property for selected user
    isModalDetail: false,
  }),
  methods: {
    formatPrice(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    getDiscounts(discounts) {
      return discounts
        .map((discount) => `IDR. ${this.formatPrice(discount)}`)
        .join("<br /> ");
    },
    getVouchers(vouchers) {
      return vouchers.map((voucher) => `${voucher}`).join("<br />");
    },
    editUser(item) {
      this.$router.push(
        "/transaction/b2b/event/participants/edit/" + item.name
      );
    },
    viewUser(user) {
      this.selectedUser = user;
      this.isModalDetail = true;
    },
    calculateTotalPrice(no_transaction) {
      const normalPrice = no_transaction[0];
      const discounts = no_transaction[1].discounts;
      const totalDiscount = discounts.reduce((acc, curr) => acc + curr, 0);
      return normalPrice - totalDiscount;
    },
  },
};
</script>
  
    
    <style scoped>
.main {
  margin: 50px 40px;
}

.breadcrumb-download {
  display: flex;
  justify-content: space-between;
}

.btn-riwayat {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.5px solid #d3d6e1;
  background-color: #fff;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  color: #7b7e8c;
  width: 156px;
  height: 44px;
  padding: 6px 30px 6px 30px;
}
.btn-actions {
  display: flex;
  gap: 15px;
}
.btn-watch {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.5px solid #2d46cf80;
  background-color: #fff;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  color: #2d46cf;
  width: 79px;
  height: 38px;
  padding: 8px 20px 8px 20px;
}
.btn-edit {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.5px solid #d3d6e1;
  background-color: #fff;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  color: #7b7e8c;
  width: 79px;
  height: 38px;
  padding: 8px 20px 8px 20px;
}
::v-deep .v-breadcrumbs__item {
  color: #162ca2 !important;
  font-size: 14px !important;
  font-weight: 600;
}

::v-deep .v-breadcrumbs__item--disabled {
  color: #7b7e8c !important;
}

.table-container {
  margin: 34px 15px;
  width: 100%;
  height: auto;
  padding: 40px;
  background-color: #fff;
  border-radius: 20px;
}

.table-wrapper {
  overflow-x: auto;
}

.filter {
  display: flex;
  gap: 18px;
  width: 50%;
  justify-content: flex-start;
}

.transact-item {
  display: flex;
  gap: 30px;
}

.transact-item p {
  width: 100px;
}

.discount-item {
  display: flex;
  flex-direction: column;
}

tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.info-icon-wrapper {
  position: relative;
  display: inline-block;
}

.tooltip {
  visibility: hidden;
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  font-size: 12px;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #333 transparent;
}

.info-icon-wrapper:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.info-icon {
  cursor: pointer;
}
</style>
    